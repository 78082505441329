import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    InputAdornment,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLineContext } from "../../../hooks/useLineContext";
import { baseURL } from "../../../api/url";
import { utils, writeFileXLSX } from "xlsx";
import _ from "lodash";
import dayjs from "dayjs";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import RedButton from "../../../components/ui/RedButton";
import DataTable from "react-data-table-component";
import InputField from "../../../components/ui/InputField";
import DialogInfo from "../../../components/ui/DialogInfo";

const customStyles = {
    headRow: {
        style: {
            fontSize: ".75rem",
            color: "white",
            background: "linear-gradient(0deg, #365CA0, #264373, #152741)",
        },
    },
    headCells: {
        style: {
            justifyContent: "center",
            borderLeft: "1px solid white",
        },
    },
    rows: {
        style: {
            background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
            cursor: "pointer",
            "&:not(:last-of-type)": {
                borderBottomColor: "white",
            },
        },
    },
    cells: {
        style: {
            padding: ".5rem",
            "& div:first-child": {
                whiteSpace: "pre-wrap !important",
                overflow: "auto !important",
                textOverflow: "unset !important",
            },
        },
    },
};

const customStyles2 = {
    ...customStyles,
    cells: {
        style: {
            padding: "0",
            "& div:first-child": {
                whiteSpace: "pre-wrap !important",
                overflow: "auto !important",
                textOverflow: "unset !important",
            },
        },
    },
};

const AdminTabTwo = () => {
    const { liffToken } = useLineContext();
    const inputApproveRef = useRef();
    const [history, setHistory] = useState([]);
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 50,
        sortColumn: "create_timestamp",
        isAsc: false,
    });
    const [totalRecords, setTotalRecords] = useState(0);
    const [approveData, setApproveData] = useState({});
    const [confirmApproveDialog, setConfirmApproveDialog] = useState(false);
    const [verifyCode, setVerifyCode] = useState("");
    const [verifyCodeError, setVerifyCodeError] = useState("");
    const [approveErrorDialog, setApproveErrorDialog] = useState(false);
    const [approveSuccessDialog, setApproveSuccessDialog] = useState(false);
    const [isSearch, setIsSearch] = useState(true);

    const columns = [
        {
            id: "create_timestamp",
            name: "วันเวลา",
            width: "180px",
            sortable: true,
            cell: (row) => (row.create_timestamp ? dayjs(row.create_timestamp).format("DD/MM/YYYY HH:mm:ss") : "-"),
            style: {
                padding: "0 .5rem",
            },
        },
        {
            id: "company_code",
            name: "รหัสบริษัท",
            width: "150px",
            sortable: true,
            cell: (row) => row.company_code || "-",
            style: {
                padding: "0 .5rem",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "company_name",
            name: "ชื่อบริษัท",
            width: "220px",
            sortable: true,
            cell: (row) => row.company_name || "-",
            style: {
                padding: "0 .5rem",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "reward_name",
            name: "รางวัลที่แลก",
            minWidth: "150px",
            sortable: false,
            cell: (row) =>
                row.list_reward.map((item, idx) => (
                    <Box
                        key={idx}
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "50px",
                            borderBottom: row.length - 1 === idx ? "none" : "1px solid white",
                            padding: "0 .5rem",
                        }}
                    >
                        {item.name}
                    </Box>
                )),
            style: {
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "reward_quantity",
            name: "จำนวน",
            width: "120px",
            sortable: false,
            cell: (row) =>
                row.list_reward.map((item, idx) => (
                    <Box
                        key={idx}
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "50px",
                            borderBottom: row.length - 1 === idx ? "none" : "1px solid white",
                            padding: "0 .5rem",
                        }}
                    >
                        {item.quantity}
                    </Box>
                )),
            style: {
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "reward_point",
            name: "คะแนนที่ใช้",
            width: "150px",
            sortable: false,
            cell: (row) =>
                row.list_reward.map((item, idx) => (
                    <Box
                        key={idx}
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "50px",
                            borderBottom: row.length - 1 === idx ? "none" : "1px solid white",
                            padding: "0 .5rem",
                        }}
                    >
                        {item.point}
                    </Box>
                )),
            style: {
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "order_id",
            name: "Order ID",
            width: "150px",
            sortable: true,
            button: true,
            cell: (row) =>
                !row.order_id ? (
                    <Button
                        onClick={() => onApproveClick(row)}
                        sx={{
                            color: "white",
                            backgroundColor: "#002B38",
                            padding: ".25rem 1rem",
                            "&:hover": { color: "white", backgroundColor: "#002B38", opacity: ".85" },
                        }}
                    >
                        อนุมัติ
                    </Button>
                ) : (
                    row.order_id
                ),
            style: {
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "tracking",
            name: "Tracking",
            width: "150px",
            sortable: false,
            cell: (row) =>
                row.delivery && row.tracking_url ? (
                    <Link href={row.tracking_url} sx={{ textDecoration: "underline" }} target="_blank">
                        {row.delivery}
                    </Link>
                ) : row.delivery && !row.tracking_url ? (
                    row.delivery
                ) : (
                    "-"
                ),
            style: {
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "name",
            name: "ชื่อผู้รับ",
            width: "180px",
            sortable: false,
            cell: (row) => row.address.name,
            style: {
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "phonenumber",
            name: "เบอร์โทรศัพท์",
            width: "180px",
            sortable: false,
            cell: (row) => row.address.phonenumber,
            style: {
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
    ];

    const fetchHistory = useCallback(
        async (page, perPage, search, sortColumn, isAsc) => {
            if (liffToken) {
                try {
                    setIsLoading(true);
                    const response = await axios.get(`${baseURL}/shipping_history`, {
                        headers: { Authorization: `Bearer ${liffToken}` },
                        params: {
                            page,
                            search,
                            limit: perPage,
                            sort_col: sortColumn,
                            sort_value: isAsc,
                        },
                    });

                    if (response.data.Code === "20000") {
                        setTotalRecords(response.data.Data.total);
                        setHistory(response.data.Data.data);
                    }
                    setIsLoading(false);
                    setIsSearch(false);
                } catch (e) {
                    setIsLoading(false);
                    console.error(e);
                }
            }
        },
        [liffToken]
    );

    const handleDownloadExcel = (dataSource, sheetName, fileName, headerOrder = { header: [] }) => {
        const ws = utils.json_to_sheet(dataSource, headerOrder);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, sheetName);
        writeFileXLSX(wb, `${fileName}.xlsx`);
    };

    const handleDownload = async () => {
        try {
            const response = await axios.get(`${baseURL}/shipping_history`, {
                headers: { Authorization: `Bearer ${liffToken}` },
                params: {
                    page: 1,
                    search: "",
                    limit: "all",
                    sort_col: "create_timestamp",
                    sort_value: false,
                },
            });

            if (response.data.Code === "20000") {
                const allItems = response.data.Data.data.map((item) => {
                    return item.list_reward.map((i) => ({
                        create_timestamp: item.create_timestamp,
                        code: item.company_code,
                        company_name: item.company_name,
                        reward_name: i.name,
                        reward_quantity: i.quantity,
                        reward_point: i.point,
                        name: item.address.name,
                        phonenumber: item.address.phonenumber,
                        tracking: item.tracking_url,
                        id: item._id,
                        state: item.state,
                        order_id: item.order_id,
                    }));
                });

                const allItemsNewFormat = _.flatMapDeep(allItems);

                handleDownloadExcel(
                    allItemsNewFormat,
                    "Redeem History",
                    `STARCARD_CLUB_REDEEM_${dayjs().format("DD-MMM-YYYY")}`,
                    {
                        header: [
                            "create_timestamp",
                            "code",
                            "company_name",
                            "reward_name",
                            "reward_quantity",
                            "reward_point",
                            "name",
                            "phonenumber",
                            "id",
                            "state",
                            "order_id",
                        ],
                    }
                );
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleSort = (selectedColumn, sortDirection) => {
        setIsLoading(true);
        setIsSearch(true);
        setPagination((prev) => ({
            ...prev,
            sortColumn: selectedColumn.id || "create_timestamp",
            isAsc: sortDirection === "asc",
        }));
    };

    const handlePerRowsChange = (newPerPage) => {
        setIsLoading(true);
        setIsSearch(true);
        setPagination((prev) => ({
            ...prev,
            page: 1,
            perPage: newPerPage,
        }));
    };

    const handlePageChange = (page) => {
        setIsLoading(true);
        setIsSearch(true);
        setPagination((prev) => ({
            ...prev,
            page,
        }));
    };

    const onApproveClick = (data) => {
        setApproveData(data);
        setConfirmApproveDialog(true);
    };

    const handleSearch = () => {
        fetchHistory(1, 50, search, "create_timestamp", false);
    };

    const handleCloseApproveRedeem = () => {
        setVerifyCodeError("");
        setConfirmApproveDialog(false);
    };

    const onApproveRedeem = () => {
        if (inputApproveRef.current.value === verifyCode) {
            setVerifyCodeError("");

            const prepareData = approveData.list_reward.map((item) => {
                return {
                    shipping_id: approveData._id,
                    state: "approve",
                };
            });

            axios
                .post(`${baseURL}/update_state_shipping`, prepareData, {
                    headers: {
                        Authorization: `Bearer ${liffToken}`,
                    },
                })
                .then((res) => {
                    if (res.data.Code !== "20000") {
                        setApproveErrorDialog(true);
                    }

                    setApproveSuccessDialog(true);
                    fetchHistory(1, pagination.perPage, "", pagination.sortColumn, pagination.isAsc);
                    handleCloseApproveRedeem();
                })
                .catch((e) => {
                    handleCloseApproveRedeem();
                    setApproveErrorDialog(true);
                    console.log("Can't approve: ", e);
                });
        } else {
            setVerifyCodeError("รหัสไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง");
        }
    };

    const makeCode = (length) => {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    };

    useEffect(() => {
        const timerId = setTimeout(() => {
            if (isSearch) {
                fetchHistory(pagination.page, pagination.perPage, search, pagination.sortColumn, pagination.isAsc);
            }
        }, 500);

        return () => clearTimeout(timerId);
    }, [fetchHistory, pagination, search, isSearch]);

    useEffect(() => {
        if (confirmApproveDialog) {
            setVerifyCode(makeCode(5));
        }
    }, [confirmApproveDialog]);

    return (
        <Box>
            <Box
                p={1}
                sx={{
                    backgroundColor: "white",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                    border: "2px solid white",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
            >
                <Box display="flex" alignItems="center" gap={1} mb={2}>
                    <TextField
                        fullWidth
                        placeholder="ค้นหารหัสบริษัท"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: "#004B61" }} />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            fontSize: "0.875rem",
                            borderRadius: "15px",
                            "& .MuiInputBase-root": {
                                borderRadius: "15px",
                                backgroundColor: "white",
                            },
                            "& .MuiInputBase-input": {
                                padding: "0",
                                height: "40px",
                                color: "#004B61",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                fontSize: "0.875rem",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "15px",
                                border: "2px solid #004B61",
                            },
                        }}
                    />
                    <RedButton sx={{ width: "40%", maxWidth: "117px", fontSize: ".75rem" }} onClick={handleSearch}>
                        ค้นหา
                    </RedButton>
                </Box>
                <Box pb={3} display="flex" justifyContent="end" gap={2} sx={{ position: "relative" }}>
                    <Box
                        sx={{
                            width: "190px",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <RedButton sx={{ fontSize: ".75rem" }} onClick={handleDownload}>
                            Export Excel
                        </RedButton>
                    </Box>
                </Box>

                <DataTable
                    pagination
                    paginationServer
                    paginationPerPage={pagination.perPage}
                    paginationTotalRows={totalRecords}
                    paginationDefaultPage={pagination.page}
                    paginationRowsPerPageOptions={[50, 100, 500]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    columns={columns}
                    data={history}
                    customStyles={customStyles2}
                    progressPending={isLoading}
                    onSort={handleSort}
                    defaultSortFieldId={pagination.sortColumn}
                    defaultSortAsc={pagination.isAsc}
                    progressComponent={
                        <Box
                            py={5}
                            sx={{
                                width: "100%",
                                height: "100%",
                                background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    }
                    noDataComponent={
                        <Box
                            py={5}
                            sx={{
                                width: "100%",
                                height: "100%",
                                background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
                            }}
                        >
                            <Typography align="center" sx={{ fontSize: "1.25rem", color: "#004B61" }}>
                                ไม่พบประวัติการแลกรางวัล
                            </Typography>
                        </Box>
                    }
                />

                <Dialog
                    open={confirmApproveDialog}
                    onClose={handleCloseApproveRedeem}
                    sx={{
                        "& .MuiPaper-root": {
                            maxWidth: "unset",
                        },
                    }}
                >
                    <Paper
                        sx={{
                            minWidth: "850px",
                            backgroundColor: "white",
                            padding: "2rem",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "1rem",
                                color: "#333",
                                textAlign: "center",
                                marginBottom: "1.25rem",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            ต้องการอนุมัติรายการแลกของรางวัล ใช่หรือไม่
                        </Typography>

                        <Paper
                            elevation={0}
                            sx={{
                                borderTop: "1px solid rgba(224, 224, 224, 1)",
                                borderLeft: "1px solid rgba(224, 224, 224, 1)",
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                            }}
                        >
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow
                                            sx={{
                                                background:
                                                    "linear-gradient(90deg, #002B38, #002B38, #002B38, #005D76, #006B7D)",
                                            }}
                                        >
                                            <TableCell
                                                sx={{
                                                    minWidth: "120px",
                                                    textAlign: "center",
                                                    color: "white",
                                                }}
                                            >
                                                วันเวลา
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    minWidth: "100px",
                                                    textAlign: "center",
                                                    color: "white",
                                                }}
                                            >
                                                รหัสบริษัท
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    minWidth: "150px",
                                                    textAlign: "center",
                                                    color: "white",
                                                }}
                                            >
                                                ชื่อบริษัท
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    minWidth: "180px",
                                                    textAlign: "center",
                                                    color: "white",
                                                }}
                                            >
                                                รางวัลที่แลก
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    minWidth: "100px",
                                                    textAlign: "center",
                                                    color: "white",
                                                }}
                                            >
                                                จำนวน
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    minWidth: "100px",
                                                    textAlign: "center",
                                                    color: "white",
                                                }}
                                            >
                                                คะแนนที่ใช้
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!_.isEmpty(approveData) &&
                                            approveData.list_reward.map((item, idx) => (
                                                <TableRow key={idx}>
                                                    <TableCell>
                                                        <Typography
                                                            sx={{
                                                                fontSize: ".75rem",
                                                                color: "#333",
                                                                textAlign: "center",
                                                                marginBottom: "1.25rem",
                                                                whiteSpace: "pre-wrap",
                                                            }}
                                                        >
                                                            {dayjs(approveData.create_timestamp).format(
                                                                "DD/MM/YYYY HH:mm:ss"
                                                            )}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            sx={{
                                                                fontSize: ".75rem",
                                                                color: "#333",
                                                                textAlign: "center",
                                                                marginBottom: "1.25rem",
                                                                whiteSpace: "pre-wrap",
                                                            }}
                                                        >
                                                            {approveData.code || "-"}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            sx={{
                                                                fontSize: ".75rem",
                                                                color: "#333",
                                                                textAlign: "center",
                                                                marginBottom: "1.25rem",
                                                                whiteSpace: "pre-wrap",
                                                            }}
                                                        >
                                                            {approveData.company_name || "-"}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            sx={{
                                                                fontSize: ".75rem",
                                                                color: "#333",
                                                                textAlign: "center",
                                                                marginBottom: "1.25rem",
                                                                whiteSpace: "pre-wrap",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            sx={{
                                                                fontSize: ".75rem",
                                                                color: "#333",
                                                                textAlign: "center",
                                                                marginBottom: "1.25rem",
                                                                whiteSpace: "pre-wrap",
                                                            }}
                                                        >
                                                            {item.quantity}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            sx={{
                                                                fontSize: ".75rem",
                                                                color: "#333",
                                                                textAlign: "center",
                                                                marginBottom: "1.25rem",
                                                                whiteSpace: "pre-wrap",
                                                            }}
                                                        >
                                                            {item.point}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                        <Box>
                            <Typography
                                sx={{
                                    fontSize: "1.5rem",
                                    color: "#333",
                                    textAlign: "center",
                                    marginBottom: "1.25rem",
                                    whiteSpace: "pre-wrap",
                                    margin: "1rem auto",
                                }}
                            >
                                {verifyCode}
                            </Typography>
                            <InputField
                                inputRef={inputApproveRef}
                                name="verify_code"
                                placeholder="กรุณากรอกรหัสเพื่อยืนยัน"
                                error={verifyCodeError ? true : false}
                                inputProps={{
                                    maxLength: 8,
                                }}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "1rem auto",
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: ".ึ5rem",
                                    textAlign: "center",
                                    color: "#ED1C24",
                                    whiteSpace: "pre-wrap",
                                    margin: "1rem auto 2rem",
                                }}
                            >
                                {verifyCodeError}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "0.5rem",
                                maxWidth: "300px",
                                margin: "0 auto",
                            }}
                        >
                            <Button
                                sx={{
                                    fontSize: "1rem",
                                    color: "white",
                                    backgroundColor: "#ED1C24",
                                    padding: ".5rem 2rem",
                                    display: "block",
                                    margin: "0 auto",
                                    "&:hover": {
                                        color: "white",
                                        backgroundColor: "#ED1C24",
                                        opacity: ".75",
                                    },
                                }}
                                onClick={handleCloseApproveRedeem}
                            >
                                ยกเลิก
                            </Button>
                            <Button
                                sx={{
                                    fontSize: "1rem",
                                    color: "white",
                                    backgroundColor: "#004B61",
                                    padding: ".5rem 2rem",
                                    display: "block",
                                    margin: "0 auto",
                                    "&:hover": {
                                        color: "white",
                                        backgroundColor: "#004B61",
                                        opacity: ".75",
                                    },
                                }}
                                onClick={onApproveRedeem}
                            >
                                ตกลง
                            </Button>
                        </Box>
                    </Paper>
                </Dialog>

                <DialogInfo
                    isOpen={approveErrorDialog}
                    onClose={() => setApproveErrorDialog(false)}
                    text="เกิดข้อผิดพลาด ไม่สามารถอนุมัติรายการได้"
                    btnText="ปิด"
                />

                <DialogInfo
                    isOpen={approveSuccessDialog}
                    onClose={() => setApproveSuccessDialog(false)}
                    text="อนุมัติรายการสำเร็จ"
                    btnText="ปิด"
                    btnColor="#4BB543"
                />
            </Box>
        </Box>
    );
};

export default AdminTabTwo;
