import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { baseURL } from "../../api/url";
import { useLineContext } from "../../hooks/useLineContext";
import { useAppContext } from "../../hooks/useAppContext";
import AddIcon from "@mui/icons-material/Add";
import AddressCard from "./components/AddressCard";
import mainBgWhite from "../../assets/images/bg/main-bg-white.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import _ from "lodash";
import axios from "axios";

const RewardShipping = () => {
    const { liffToken } = useLineContext();
    const { companyInfo, setCompanyInfo } = useAppContext();
    const [addressLists, setAddressLists] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState("");
    const navigate = useNavigate();

    const deleteCompanyInfoAddress = useCallback(
        async (companyCode, addressId) => {
            try {
                const response = await axios.post(
                    `${baseURL}/company_delete_address`,
                    {
                        code_company: companyCode,
                        address_id: addressId,
                    },
                    { headers: { Authorization: `Bearer ${liffToken}` } }
                );

                if (response.data.Code === "20000") {
                    if (addressId === selectedAddress) {
                        setSelectedAddress("");
                    }

                    const newAddressList = _.orderBy(response?.data?.Data?.address, "default", "desc");
                    setCompanyInfo((prev) => {
                        return {
                            ...response.data.Data,
                            user: prev.user,
                            address: newAddressList,
                        };
                    });
                }
            } catch (e) {
                throw new Error("Can't delete company info address");
            }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedAddress, liffToken]
    );

    useEffect(() => {
        if (companyInfo && companyInfo.address) {
            const myAddressLists = _.orderBy(companyInfo.address, "default", "desc").map((item) => {
                if (item.default && !selectedAddress) {
                    setSelectedAddress(item._id);
                }

                return (
                    <AddressCard
                        key={item._id}
                        addressId={item._id}
                        code={companyInfo.code}
                        isDefault={item.default}
                        name={item.name}
                        detail={item.detail}
                        subdistrict={item.subdistrict}
                        district={item.district}
                        province={item.province}
                        zipcode={item.zip_code}
                        phonenumber={item.phonenumber}
                        selected={selectedAddress}
                        onDelete={deleteCompanyInfoAddress}
                        setSelected={setSelectedAddress}
                    />
                );
            });

            setAddressLists(myAddressLists);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyInfo, selectedAddress]);

    return (
        <>
            <Helmet>
                <title>Address | Caltex StarCard</title>
            </Helmet>

            <Box
                component="main"
                maxWidth="md"
                mx="auto"
                sx={{
                    height: "calc(100vh - 78px)",
                    overflow: "auto",
                    background: `url(${mainBgWhite}) no-repeat center center / cover`,
                    backgroundAttachment: "fixed",
                }}
            >
                <Box component="section" sx={{ padding: "6rem 1.25rem" }}>
                    <Typography
                        sx={{ fontSize: "1.5rem", fontWeight: "500", color: "#004B61", marginBottom: "1.25rem" }}
                    >
                        ที่อยู่ของฉัน
                    </Typography>
                    <IconButton
                        onClick={() => navigate(-1)}
                        sx={{
                            fontSize: "1rem",
                            color: "#004B61",
                            marginBottom: "1rem",
                        }}
                    >
                        <ArrowBackIosIcon
                            sx={{
                                fontSize: "1rem",
                                color: "#004B61",
                            }}
                        />
                        ย้อนกลับ
                    </IconButton>
                    {addressLists}
                    <IconButton
                        aria-label="เพิ่มที่อยู่"
                        onClick={() => navigate("/address/add")}
                        sx={{
                            display: "block",
                            width: "100%",
                            padding: "15px",
                            marginTop: "20px",
                            background: "linear-gradient(90deg, #E3EFFF, #FFFFFF)",
                            border: "2px solid white",
                            borderRadius: "20px",
                            "&:hover": {
                                backgroundColor: "#e5edefc1",
                            },
                        }}
                    >
                        <AddIcon sx={{ fontWeight: "bold", color: "#004B61" }} />
                    </IconButton>
                </Box>
            </Box>
        </>
    );
};

export default RewardShipping;
