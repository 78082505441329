import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        right: 2,
        top: 15,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: "0 4px",
    },
}));

const Cart = (props) => {
    return (
        <IconButton aria-label="cart">
            <StyledBadge badgeContent={props.quantity} sx={{ "& .MuiBadge-badge": { color: "white", backgroundColor: "#FD0004" } }}>
                <ShoppingCartIcon sx={{ fontSize: "2rem", color: "#004B61" }} />
            </StyledBadge>
        </IconButton>
    );
};

export default Cart;
