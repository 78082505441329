import { Helmet } from "react-helmet-async";
import { Box, Container } from "@mui/material";
import MemberCard from "./components/MemberCard";
import mainBg from "../../assets/images/bg/main-bg.png";
import caltexCard from "../../assets/images/pages/homepage/home-card.png";

const MemberPage = () => {
    return (
        <>
            <Helmet>
                <title>Member | Caltex StarCard</title>
            </Helmet>
            <Box
                component="main"
                maxWidth="md"
                sx={{
                    width: "100%",
                    paddingBottom: "3.57rem",
                    margin: "0 auto",
                    minHeight: "calc(100vh - 60px)",
                    background: `url(${mainBg}) no-repeat center center / cover`,
                    overflow: "hidden",
                }}
            >
                <Container component="section" maxWidth="md" sx={{ padding: "3.5rem 1.25rem" }}>
                    <Box display="flex" justifyContent="center">
                        <img
                            src={caltexCard}
                            alt="Caltex Card"
                            style={{ width: "95%", maxWidth: "550px", display: "block", margin: "2rem auto 0" }}
                        />
                    </Box>
                    <MemberCard />
                </Container>
            </Box>
        </>
    );
};

export default MemberPage;
