import { useState } from "react";
import { useAppContext } from "../../hooks/useAppContext";
import { Box, Dialog, Typography } from "@mui/material";
import RedButton from "./RedButton";
import WhiteButton from "./WhiteButton";
import DecreaseBtn from "./DecreaseBtn";
import IncreaseBtn from "./IncreaseBtn";
import InputQty from "./InputQty";
import _ from "lodash";

const DialogConfirm = (props) => {
    const { setRedeemLists } = useAppContext();
    const [count, setCount] = useState(1);
    
    const onCountChange = (e) => {
        const value = e.target.value;
        if (!value) {
            setCount("");
        } else if (value === "0") {
            setCount(1);
        } else {
            setCount(+value);
        }
    };

    const decreaseQty = () => {
        setCount((prev) => {
            if (+prev === 1) {
                return prev;
            } else {
                return +prev - 1;
            }
        });
    };

    const increaseQty = () => {
        setCount((prev) => {
            return +prev + 1;
        });
    };

    const addToCart = () => {
        const targetItem = {
            image: props.reward.image[0],
            reward_id: props.reward._id,
            quantity: count,
        };

        if (count > 0) {
            setRedeemLists((prevState) => {
                let index = _.findIndex(prevState, { reward_id: targetItem.reward_id });

                if (index !== -1) {
                    prevState.splice(index, 1, targetItem);
                    return [...prevState];
                } else {
                    return [...prevState, targetItem];
                }
            });
        }

        props.onClose();
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <Box sx={{ padding: "1.5rem" }}>
                <DialogTitle>แลกของรางวัล</DialogTitle>
                <RewardName> {props.reward?.name}</RewardName>
                <TotalRedeem>{+props?.reward?.point * count}</TotalRedeem>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: ".5rem",
                    }}
                >
                    <DecreaseBtn onClick={decreaseQty} />
                    <Box sx={{ width: "calc(100% - 80px)" }}>
                        <InputQty value={count} onChange={onCountChange} />
                    </Box>
                    <IncreaseBtn onClick={increaseQty} />
                </Box>
                <Box
                    sx={{
                        paddingTop: "2rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Box width="30%">
                        <WhiteButton fullWidth onClick={props.onClose}>
                            ปิด
                        </WhiteButton>
                    </Box>
                    <Box width="65%">
                        <RedButton fullWidth onClick={addToCart}>
                            เพิ่มสินค้าลงตะกร้า
                        </RedButton>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
};

export default DialogConfirm;

const DialogTitle = (props) => {
    return (
        <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold", textAlign: "center", marginBottom: ".5rem" }}>
            {props.children}
        </Typography>
    );
};

const RewardName = (props) => {
    return (
        <Typography sx={{ fontSize: "1.25rem", fontWeight: "500", textAlign: "center", marginBottom: ".5rem" }}>
            {props.children}
        </Typography>
    );
};

const TotalRedeem = (props) => {
    return (
        <Box
            sx={{
                paddingBottom: "1rem",
                fontSize: "1.25rem",
                fontWeight: "500",
                color: "#333",
                textAlign: "center",
            }}
        >
            คะแนนที่ใช้{" "}
            <Box component="span" sx={{ color: "#004B61" }}>
                {props.children}
            </Box>{" "}
            คะแนน
        </Box>
    );
};
