import { Box, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import mainBg from "../../assets/images/bg/main-bg.png";
import logo from "../../assets/images/logo/main-logo.png";

const EvaluationThankyouPage = () => {
    return (
        <>
            <Helmet>
                <title>Evaluation Thank You | Caltex StarCard</title>
            </Helmet>
            <Box
                component="main"
                maxWidth="md"
                sx={{
                    width: "100%",
                    paddingBottom: "0",
                    margin: "0 auto",
                    minHeight: "100vh",
                    background: `url(${mainBg}) no-repeat center center / cover`,
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box sx={{ marginBottom: "2rem" }}>
                    <Box
                        component="img"
                        src={logo}
                        alt="Logo"
                        sx={{ width: "100%", maxWidth: "320px", display: "block", margin: "0 auto .5rem" }}
                    />
                    <Typography
                        sx={{
                            fontSize: "1.5rem",
                            fontWeight: "500",
                            color: "white",
                            textAlign: "center",
                            marginBottom: ".5rem",
                        }}
                    >
                        ขอบคุณที่ส่งแบบประเมิน
                    </Typography>
                    <Typography sx={{ fontSize: ".875rem", fontWeight: "300", color: "white", textAlign: "center" }}>
                        ความคิดเห็นของคุณมีค่าสำหรับเรา <br />
                        ขอบคุณที่ใช้เวลาและความพยายามในการกรอกแบบประเมิน
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default EvaluationThankyouPage;
