import { Button } from "@mui/material";

const DecreaseBtn = (props) => {
    return (
        <Button
            onClick={props.onClick}
            sx={{
                width: "30px",
                minWidth: "unset",
                height: "30px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                color: "white",
                backgroundColor: "#ED1C24",
                "&:hover": {
                    backgroundColor: "#ED1C24",
                },
            }}
        >
            -
        </Button>
    );
};

export default DecreaseBtn;
