import { TextField } from "@mui/material";

const InputField = (props) => {
    return (
        <TextField
            {...props}
            sx={{
                ...props.sx,
                fontSize: "0.875rem",
                "& .MuiInputBase-root": {
                    borderRadius: "30px",
                    backgroundColor: "white",
                },
                "& .MuiInputBase-input": {
                    padding: "0 1.25rem",
                    height: "50px",
                    color: "#004B61",
                },
                "& .MuiOutlinedInput-root.Mui-disabled": {
                    color: "#004B61",
                    WebkitTextFillColor: "inherit",
                },
                "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "inherit",
                },
                "& .MuiInputBase-input::placeholder": {
                    fontSize: "0.875rem",
                },
            }}
        />
    );
};

export default InputField;
