import { CircularProgress, Dialog, Fade, Paper, Typography } from "@mui/material";

const DialogLoading = (props) => {
    return (
        <Dialog open={props.open} disableEscapeKeyDown>
            <Paper
                sx={(theme) => ({
                    padding: "5rem 3.5rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    [theme.breakpoints.up("sm")]: {
                        padding: "5rem 8rem",
                    },
                })}
            >
                <CircularProgress sx={{ marginBottom: "1.5rem" }} />
                <Fade in={true} easing="ease-in-out" timeout={400}>
                    <Typography
                        sx={(theme) => ({
                            fontSize: "1.5rem",
                            fontWeight: 500,
                            textAlign: "center",
                            color: "#333",
                            lineHeight: "2.5rem",
                            marginBottom: ".5rem",
                            [theme.breakpoints.up("sm")]: {
                                fontSize: "2rem",
                            },
                        })}
                    >
                        {props.message}
                    </Typography>
                </Fade>
                <Fade in={true} easing="ease-in-out" timeout={400}>
                    <Typography sx={{ fontSize: "1rem", fontWeight: 400, textAlign: "center", color: "#999" }}>
                        กรุณาอย่าปิดหน้าเว็บ
                    </Typography>
                </Fade>
            </Paper>
        </Dialog>
    );
};

export default DialogLoading;
