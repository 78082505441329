import { useState, useEffect, createContext } from "react";
import liff from "@line/liff";

export const LineContext = createContext(null);

const LineContextProvider = ({ children }) => {
    const [lineProfile, setLineProfile] = useState({});
    const [liffToken, setLiffToken] = useState("");

    useEffect(() => {
        liff.init({
            liffId: process.env.REACT_APP_LIFF_ID,
            withLoginOnExternalBrowser: false,
        })
            .then(() => {
                if (!liff.isLoggedIn()) {
                    const pageURL = window.location.href;
                    liff.login({ redirectUri: pageURL });
                } else {
                    const token = liff.getAccessToken();

                    liff.getProfile()
                        .then((res) => {
                            setLineProfile(res);
                        })
                        .catch((e) => {
                            throw new Error("Can't get line profile", e);
                        });
                        
                    setLiffToken(token);
                }
            })
            .catch((e) => {
                throw new Error("Can't init liff");
            });
    }, []);

    return <LineContext.Provider value={{ liffToken, lineProfile }}>{children}</LineContext.Provider>;
};

export default LineContextProvider;
