import { useLocation } from "react-router-dom";
import { useAppContext } from "../hooks/useAppContext";
import { useEffect } from "react";
import { Box } from "@mui/material";
import Fixbar from "./Fixbar";
import Header from "./Header";

const Layout = (props) => {
    const { renderHeader, setRenderHeader, setHeaderWhiteTheme } = useAppContext();
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname !== "/") {
            setRenderHeader(true);
        }

        if (pathname === "/register" || pathname === "/home" || pathname.includes("evaluate_satisfaction")) {
            setRenderHeader(false);
        }

        if (
            pathname.includes("/reward") ||
            pathname.includes("/privilege") ||
            pathname.includes("/history") ||
            pathname.includes("/address") ||
            pathname.includes("/shopping") ||
            pathname.includes("/admin")
        ) {
            setHeaderWhiteTheme(false);
        } else {
            setHeaderWhiteTheme(true);
        }

        // eslint-disable-next-line
    }, [pathname]);

    return (
        <>
            <Box>
                {renderHeader && <Header />}
                {props.children}
                {pathname !== "/register" &&
                    pathname !== "/" &&
                    !pathname.includes("/admin") &&
                    !pathname.includes("/evaluate_satisfaction") && <Fixbar />}
            </Box>
        </>
    );
};

export default Layout;
