import { Box, Typography } from "@mui/material";

const EmptyCard = (props) => {
    return (
        <Box px={3} py={5} sx={{ width: "100%", border: "2px solid white", borderRadius: "15px", backgroundColor: "#E5EDEF" }}>
            <Typography align="center" sx={{ fontSize: "1.25rem", color: "#004B61" }}>
                {props.text}
            </Typography>
        </Box>
    );
};

export default EmptyCard;
