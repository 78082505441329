import { Button } from "@mui/material";

const RedButton = (props) => {
    return (
        <Button
            {...props}
            variant="contained"
            sx={{
                fontSize: "1rem",
                borderRadius: "15px",
                color: "white",
                backgroundColor: "#FD0004",
                border: "2px solid #FD0004",
                ...props.sx,
                "&:hover": {
                    color: "#FD0004",
                    backgroundColor: "white",
                    border: "2px solid #FD0004",
                },
                "&.Mui-disabled": {
                    color: "white",
                    backgroundColor: "#aaa",
                    borderColor: "#aaa"
                }
            }}
        >
            {props.children}
        </Button>
    );
};

export default RedButton;