import { useState, useEffect } from "react";
import { Button } from "@mui/material";

const RequestOTPButton = (props) => {
    const [disableOTPAgain, setDisableOTPAgain] = useState(true);
    const countdown = 65000;

    const onReqOTP = () => {
        setDisableOTPAgain(true);
        disableOTP();
        props.sentRequestOTP(props.phoneNumber);
    };

    const disableOTP = () => {
        setTimeout(() => {
            setDisableOTPAgain(false);
        }, countdown);
    };

    useEffect(() => {
        disableOTP();
    }, []);

    return (
        <Button
            variant="contained"
            disabled={disableOTPAgain}
            onClick={onReqOTP}
            sx={{
                fontSize: "1rem",
                borderRadius: "15px",
                color: "#004B61",
                backgroundColor: "white",
                border: "2px solid white",
                padding: "0.5rem 1.2rem",
                "&:hover": {
                    color: "#004B61",
                    backgroundColor: "white",
                    border: "2px solid white",
                },
                "&.Mui-disabled": {
                    color: "#004B61",
                    backgroundColor: "white",
                    borderColor: "white",
                    opacity: ".5"
                },
            }}
        >
            ขอรหัส OTP อีกครั้ง
        </Button>
    );
};

export default RequestOTPButton;
