import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Container } from "@mui/material";
import { useLineContext } from "../../hooks/useLineContext";
import { baseURL } from "../../api/url";
import RegisterPhone from "./components/RegisterPhone";
import RegisterOTP from "./components/RegisterOTP";
import RegisterForm from "./components/RegisterForm";
import DialogInfo from "../../components/ui/DialogInfo";
import mainBg from "../../assets/images/bg/main-bg.png";
import mainBgWhite from "../../assets/images/bg/main-bg-white.png";
import mainLogo from "../../assets/images/logo/main-logo.png";
import mainLogoDark from "../../assets/images/logo/main-logo-dark.png";
import axios from "axios";
import Footer from "../../layouts/Footer";

const RegisterPage = () => {
    const { liffToken } = useLineContext();
    const [activeStep, setActiveStep] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [renderText, setRenderText] = useState(true);

    const sentRequestOTP = async (phoneNumber) => {
        try {
            const response = await axios.post(
                `${baseURL}/request_otp`,
                { phonenumber: phoneNumber },
                { headers: { Authorization: `Bearer ${liffToken}` } }
            );

            if (response.data.Code === "20000") {
                setActiveStep(2);
            } else {
                setIsOpen(true);
            }
        } catch (e) {
            console.error("Can't send request OTP", e);
        }
    };

    const onCloseDialog = () => setIsOpen(false);

    const renderSteps = () => {
        switch (activeStep) {
            case 2:
                return (
                    <RegisterOTP
                        phoneNumber={phoneNumber}
                        setActiveStep={setActiveStep}
                        sentRequestOTP={sentRequestOTP}
                    />
                );
            case 3:
                return <RegisterForm phoneNumber={phoneNumber} activeStep={activeStep} setActiveStep={setActiveStep} />;
            case 1:
            default:
                return (
                    <>
                        <RegisterPhone
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            sentRequestOTP={sentRequestOTP}
                        />
                        <DialogInfo
                            isOpen={isOpen}
                            onClose={onCloseDialog}
                            text={"ไม่สามารถขอ OTP ได้ในขณะนี้ \nโปรดลองใหม่อีกครั้ง"}
                            btnText="ปิด"
                        />
                    </>
                );
        }
    };

    const backgroundCondition =
        activeStep <= 2
            ? `url(${mainBg}) no-repeat center center / cover, #365CA0`
            : `url(${mainBgWhite}) no-repeat center center / cover, white`;

    useEffect(() => {
        if (activeStep <= 2) {
            setRenderText(true);
        } else {
            setRenderText(false);
        }
    }, [activeStep]);

    return (
        <>
            <Helmet>
                <title>Register | Caltex StarCard</title>
            </Helmet>

            <Box component="main" maxWidth="md" mx="auto" sx={{ background: `${backgroundCondition}` }}>
                <Container component="section" sx={{ padding: "0" }}>
                    <Box
                        sx={{
                            padding: "1rem 0 0",
                            height: "calc(100vh - 100px)",
                            minHeight: "500px",
                            overflow: "auto",
                        }}
                    >
                        <Box
                            component="img"
                            src={activeStep <= 2 ? mainLogo : mainLogoDark}
                            alt="Caltex Starcard Club Logo"
                            sx={{ height: "100px", display: "block", margin: "1rem auto" }}
                        />
                        {renderSteps()}
                    </Box>
                    <Footer renderText={renderText} />
                </Container>
            </Box>
        </>
    );
};

export default RegisterPage;
