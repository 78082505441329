import { Grid, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const RewardSearch = ({ onFilterListRewardChange, onSortingChange, sorting }) => {
    return (
        <>
            <Grid container columnSpacing={1}>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        placeholder="ค้นหาของรางวัล"
                        onChange={onFilterListRewardChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: "#004B61" }} />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            fontSize: "0.875rem",
                            borderRadius: "15px",
                            "& .MuiInputBase-root": {
                                borderRadius: "15px",
                                backgroundColor: "white",
                            },
                            "& .MuiInputBase-input": {
                                padding: "0",
                                height: "40px",
                                color: "#004B61",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                fontSize: "0.875rem",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "15px",
                                border: "2px solid #004B61",
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select
                        id="sorting"
                        variant="outlined"
                        fullWidth
                        IconComponent={ExpandMoreIcon}
                        value={sorting}
                        onChange={onSortingChange}
                        sx={{
                            fontSize: "0.875rem",
                            borderRadius: "15px",
                            "& .MuiSelect-select": {
                                padding: ".5rem",
                                backgroundColor: "white",
                                border: "2px solid #004B61",
                                borderRadius: "15px",
                            },
                            "& .MuiOutlinedInput-input:focus": {
                                borderRadius: "15px",
                            },
                        }}
                    >
                        <MenuItem value="min-max">น้อย-มาก</MenuItem>
                        <MenuItem value="max-min">มาก-น้อย</MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </>
    );
};

export default RewardSearch;
