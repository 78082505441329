import { useEffect, useState } from "react";
import {
    Avatar,
    Badge,
    Box,
    CircularProgress,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useAppContext } from "../../hooks/useAppContext";
import HistoryTable from "../history/components/HistoryTable";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarRateIcon from "@mui/icons-material/StarRate";
import mainBgWhite from "../../assets/images/bg/main-bg-white.png";
import { styled } from "@mui/system";
import axios from "axios";
import { baseURL } from "../../api/url";
import { useLineContext } from "../../hooks/useLineContext";

const TopicTypo = styled(Typography)({
    fontSize: "1rem",
    fontWeight: "500",
    color: "#004B61",
    whiteSpace: "pre-wrap",
});

const ValueTypo = styled(Typography)({
    fontSize: "1rem",
    color: "#004B61",
    whiteSpace: "pre-wrap",
});

const AdminHistoryPage = () => {
    const { liffToken } = useLineContext();
    const { companyInfo, setCompanyInfo } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (liffToken) {
            if (id) {
                axios
                    .get(`${baseURL}/companyinfo`, {
                        headers: {
                            Authorization: `Bearer ${liffToken}`,
                        },
                        params: {
                            code_company: id,
                        },
                    })
                    .then((res) => {
                        if (res.data.Code === "20000") {
                            setCompanyInfo({
                                ...res.data.Data,
                                accumulated_points: res.data.Data.accumulated_points.toFixed(2),
                                spending_amount: res.data.Data.spending_amount.toFixed(2),
                                spending_criteria: res.data.Data.spending_criteria.toFixed(2),
                            });
                        }

                        setIsLoading(false);
                    })
                    .catch((e) => {
                        throw new Error("Can't get company info", e);
                    });
            }
        }
        // eslint-disable-next-line
    }, [id, liffToken]);

    return (
        <>
            <Helmet>
                <title>ประวัติการใช้งาน | Caltex StarCard</title>
            </Helmet>

            <Box
                component="main"
                mx="auto"
                sx={{
                    background: `url(${mainBgWhite}) no-repeat center center / cover`,
                    backgroundAttachment: "fixed",
                }}
            >
                <Box component="section" maxWidth="lg" mx="auto" sx={{ padding: "6rem 1.25rem" }}>
                    <Typography
                        sx={{
                            fontSize: "1.5rem",
                            fontWeight: "500",
                            color: "#002B38",
                            textAlign: "center",
                            marginBottom: "1.25rem",
                        }}
                    >
                        ประวัติการใช้งาน
                    </Typography>

                    <IconButton
                        onClick={() => navigate(-1)}
                        sx={{
                            fontSize: "1.25rem",
                            color: "#004B61",
                            marginBottom: "1rem",
                            position: "absolute",
                            top: "80px",
                            left: "16px",
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                        }}
                    >
                        <ArrowBackIosIcon
                            sx={{
                                fontSize: "1rem",
                                color: "#004B61",
                            }}
                        />
                        ย้อนกลับ
                    </IconButton>

                    <Box p={2} my={3} sx={{ backgroundColor: "white", borderRadius: "8px" }}>
                        {isLoading && <CircularProgress sx={{ display: "block", margin: "50px auto" }} />}

                        {!isLoading && (
                            <Grid container columnSpacing={2} rowSpacing={1}>
                                <Grid item xs={5}>
                                    <TopicTypo>รหัสบริษัท:</TopicTypo>
                                </Grid>
                                <Grid item xs={7}>
                                    <ValueTypo>{companyInfo.code}</ValueTypo>
                                </Grid>

                                <Grid item xs={5}>
                                    <TopicTypo>ชื่อบริษัท:</TopicTypo>
                                </Grid>
                                <Grid item xs={7}>
                                    <ValueTypo>{companyInfo.company_name}</ValueTypo>
                                </Grid>

                                <Grid item xs={5}>
                                    <TopicTypo>คะแนนสะสม:</TopicTypo>
                                </Grid>
                                <Grid item xs={7}>
                                    <ValueTypo>{companyInfo.accumulated_points}</ValueTypo>
                                </Grid>

                                <Grid item xs={5}>
                                    <TopicTypo>เกณฑ์การให้คะแนน:</TopicTypo>
                                </Grid>
                                <Grid item xs={7}>
                                    <ValueTypo>{companyInfo.spending_criteria}</ValueTypo>
                                </Grid>

                                <Grid item xs={5}>
                                    <TopicTypo>ยอดที่ใช้ไปล่าสุด:</TopicTypo>
                                </Grid>
                                <Grid item xs={7}>
                                    <ValueTypo>{companyInfo.spending_amount}</ValueTypo>
                                </Grid>

                                <Grid item xs={5}>
                                    <TopicTypo>ชื่อผู้มีอำนาจ:</TopicTypo>
                                </Grid>
                                <Grid item xs={7}>
                                    <ValueTypo>{companyInfo.people_name}</ValueTypo>
                                </Grid>

                                <Grid item xs={5}>
                                    <TopicTypo>รายชื่อผู้ใช้งาน:</TopicTypo>
                                </Grid>
                                <Grid item xs={7}>
                                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                        {companyInfo.user.length > 0
                                            ? companyInfo.user.map((item) => {
                                                  if (item.message === "Not found") {
                                                      return (
                                                          <Box key={item.userId}>
                                                              <ListItem alignItems="center" sx={{ paddingLeft: "0" }}>
                                                                  <ListItemAvatar>
                                                                      <Avatar />
                                                                  </ListItemAvatar>
                                                                  <ListItemText
                                                                      primary={`Unknown ${item.userId}`}
                                                                      secondary={item.phonenumber}
                                                                  />
                                                              </ListItem>
                                                          </Box>
                                                      );
                                                  }

                                                  if (item.owner) {
                                                      return (
                                                          <Box key={item.userId}>
                                                              <ListItem alignItems="center" sx={{ paddingLeft: "0" }}>
                                                                  <ListItemAvatar sx={{ position: "relative" }}>
                                                                      <Avatar
                                                                          alt={item.displayName}
                                                                          src={item.pictureUrl}
                                                                          sx={{ border: "1px solid #ddd" }}
                                                                      />
                                                                      <Badge
                                                                          color="primary"
                                                                          sx={{
                                                                              position: "absolute",
                                                                              top: "-10px",
                                                                              right: "10px",
                                                                          }}
                                                                      >
                                                                          <StarRateIcon sx={{ color: "#FFC93C" }} />
                                                                      </Badge>
                                                                  </ListItemAvatar>
                                                                  <ListItemText
                                                                      primary={item.displayName}
                                                                      secondary={item.phonenumber || ""}
                                                                  />
                                                                  <ListItemText />
                                                              </ListItem>
                                                          </Box>
                                                      );
                                                  }

                                                  return (
                                                      <Box key={item.userId}>
                                                          <ListItem alignItems="center" sx={{ paddingLeft: "0" }}>
                                                              <ListItemAvatar>
                                                                  <Avatar
                                                                      alt={item.displayName}
                                                                      src={item.pictureUrl}
                                                                  />
                                                              </ListItemAvatar>
                                                              <ListItemText
                                                                  primary={item.displayName}
                                                                  secondary={item.phonenumber}
                                                              />
                                                          </ListItem>
                                                      </Box>
                                                  );
                                              })
                                            : null}
                                    </List>
                                </Grid>
                            </Grid>
                        )}
                    </Box>

                    <HistoryTable userInfo={companyInfo} />
                </Box>
            </Box>
        </>
    );
};

export default AdminHistoryPage;
