import { Button, Dialog, Paper, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const DialogSuccess = (props) => {
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <Paper sx={{ minWidth: "300px", backgroundColor: "white", padding: "2rem 5rem" }}>
                <CheckCircleIcon
                    sx={{ fontSize: "5.5rem", display: "block", margin: "0 auto 10px", color: "#4BB543" }}
                />
                <Typography
                    sx={{
                        fontSize: "1.5rem",
                        color: "#333",
                        textAlign: "center",
                        marginBottom: "1.25rem",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {props.text}
                </Typography>
                <Button
                    sx={{
                        fontSize: "0.75rem",
                        color: "white",
                        backgroundColor: props.btnColor ? props.btnColor : "#ED1C24",
                        padding: ".5rem 2rem",
                        display: "block",
                        margin: "0 auto",
                        "&:hover": {
                            color: "white",
                            backgroundColor: props.btnColor ? props.btnColor : "#ED1C24",
                            opacity: ".75",
                        },
                    }}
                    onClick={props.onClose}
                >
                    {props.btnText}
                </Button>
            </Paper>
        </Dialog>
    );
};

export default DialogSuccess;
