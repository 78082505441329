import { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { baseURL } from "../../../api/url";
import { useLineContext } from "../../../hooks/useLineContext";
import _ from "lodash";
import RewardItem from "./RewardItem";
import RewardSearch from "./RewardSearch";
import EmptyCard from "../../../components/ui/EmptyCard";
import axios from "axios";

const RewardList = () => {
    const { liffToken } = useLineContext();
    const [listReward, setListReward] = useState([]);
    const [filterListReward, setFilterListReward] = useState([]);
    const [term, setTerm] = useState("");
    const [sorting, setSorting] = useState("min-max");
    const [isLoading, setIsLoading] = useState(true);

    const renderListReward = (list) => {
        if (list?.length > 0) {
            let newList;
            if (sorting === "min-max") {
                newList = _.orderBy(list, ["point"], "asc");
            } else {
                newList = _.orderBy(list, ["point"], "desc");
            }

            return newList?.map((reward) => {
                return (
                    <RewardItem
                        key={reward?._id}
                        id={reward?._id}
                        images={reward?.image}
                        point={reward?.point}
                        name={reward?.name}
                        detail={reward?.detail}
                        unit={reward?.unit}
                        quantity={reward?.quantity}
                    />
                );
            });
        } else {
            if (isLoading) {
                return;
            }

            return <EmptyCard text="ไม่พบของรางวัล" />;
        }
    };

    const onFilterListRewardChange = (event) => {
        const value = event.target.value;
        const newListReward = listReward.filter((reward) => {
            return reward.name.toLowerCase().includes(value.toLowerCase()) || reward.point.toString().includes(value);
        });

        setTerm(value);
        setFilterListReward(newListReward);
    };

    const onSortingChange = (e) => {
        setSorting(e.target.value);
    };

    useEffect(() => {
        if (liffToken) {
            axios
                .get(`${baseURL}/listreward`, {
                    headers: { Authorization: `Bearer ${liffToken}` },
                })
                .then((res) => {
                    if (res.data.Code === "20000") {
                        setListReward(res.data.Data);
                    } else {
                        setListReward([]);
                    }

                    setIsLoading(false);
                })
                .catch((e) => {
                    throw new Error("Can't get list rewards");
                });
        }
    }, [liffToken]);

    useEffect(() => {
        switch (sorting) {
            case "min-max":
                return setListReward((prev) => _.orderBy(prev, ["point"], "asc"));
            case "max-min":
                return setListReward((prev) => _.orderBy(prev, ["point"], "desc"));
            default:
                return setListReward((prev) => _.orderBy(prev, ["point"], "asc"));
        }
    }, [sorting]);

    return (
        <>
            <Box p={2.5} display="flex" flexDirection="column" gap={2}>
                <RewardSearch
                    onFilterListRewardChange={onFilterListRewardChange}
                    onSortingChange={onSortingChange}
                    sorting={sorting}
                />
                <Box
                    sx={(theme) => ({
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        [theme.breakpoints.up("sm")]: {
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            "& a": {
                                width: "48.5%",
                            },
                        },
                    })}
                >
                    {isLoading && <CircularProgress thickness={5} sx={{ display: "block", margin: "20px auto"}}/>}

                    {!term ? renderListReward(listReward) : renderListReward(filterListReward)}
                </Box>
            </Box>
        </>
    );
};

export default RewardList;
