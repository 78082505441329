import { Helmet } from "react-helmet-async";
import { Box, Container, Typography } from "@mui/material";
import mainBgWhite from "../../assets/images/bg/main-bg-white.png";
import homeCard from "../../assets/images/pages/homepage/home-card.png";
import mainLogoDark from "../../assets/images/logo/main-logo-dark.png";
import homeBg from "../../assets/images/pages/homepage/home-bg.png";
import home2024 from "../../assets/images/pages/homepage/home-2024.png";
import giftBox from "../../assets/icon/giftbox.svg";

const HomePage = () => {
    return (
        <>
            <Helmet>
                <title>Homepage | Caltex StarCard</title>
            </Helmet>
            <Box
                component="main"
                maxWidth="md"
                sx={{
                    width: "100%",
                    paddingBottom: "3.57rem",
                    margin: "0 auto",
                    minHeight: "calc(100vh - 60px)",
                    background: `url(${mainBgWhite}) no-repeat center center / cover`,
                    overflow: "hidden",
                    position: "relative",
                }}
            >
                <Container component="section" maxWidth="md" sx={{ padding: "0.5rem 1.25rem 5rem" }}>
                    <Box
                        component="img"
                        src={mainLogoDark}
                        alt="Caltex Starclub Logo"
                        sx={{ height: "100px", display: "block", margin: "0 auto" }}
                    />
                    <Typography
                        component="h2"
                        sx={{
                            fontSize: "1.75rem",
                            fontWeight: "500",
                            color: "#004B61",
                            textAlign: "center",
                        }}
                    >
                        ยินดีต้อนรับสู่​
                    </Typography>
                    <Typography
                        component="h1"
                        sx={{
                            fontSize: "3rem",
                            fontWeight: "bold",
                            lineHeight: "2.5rem",
                            color: "#004B61",
                            textAlign: "center",
                        }}
                    >
                        สตาร์
                        <Box component="span" sx={{ fontWeight: "500" }}>
                            การ์ด คลับ
                        </Box>
                    </Typography>
                    <Box
                        component="img"
                        src={home2024}
                        alt="2024"
                        sx={{
                            width: "100%",
                            maxWidth: "200px",
                            display: "block",
                            margin: "0 auto -20px",
                            position: "relative",
                            left: "75px",
                        }}
                    />
                    <Box
                        component="img"
                        src={homeCard}
                        alt="Card"
                        sx={{
                            width: "100%",
                            maxWidth: "560px",
                            display: "block",
                            margin: "0 auto",
                        }}
                    />
                    <Box sx={{ position: "relative", zIndex: "2" }}>
                        <Typography
                            component="h3"
                            sx={(theme) => ({
                                fontSize: "1.9rem",
                                fontWeight: "bold",
                                color: "#004B61",
                                textAlign: "center",
                                lineHeight: "1.5rem",
                                [theme.breakpoints.up("sm")]: {
                                    fontSize: "2.5rem",
                                },
                            })}
                        >
                            เปลี่ยนทุกลิตรเป็นคะแนน​
                        </Typography>
                        <Typography
                            component="h3"
                            sx={(theme) => ({
                                fontSize: "1.9rem",
                                fontWeight: "400",
                                color: "#004B61",
                                textAlign: "center",
                                [theme.breakpoints.up("sm")]: {
                                    fontSize: "2rem",
                                },
                            })}
                        >
                            เพื่อแลกของรางวัล ง่ายๆ
                        </Typography>
                        <Box
                            sx={{
                                width: "fit-content",
                                padding: "0.5rem 1rem",
                                backgroundColor: "#FF0000",
                                borderRadius: "15px",
                                margin: "0.5rem auto .75rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <Typography
                                component="h3"
                                sx={(theme) => ({
                                    fontSize: "1.5rem",
                                    fontWeight: "bold",
                                    color: "white",
                                    textAlign: "center",
                                    letterSpacing: "0.1rem",
                                    [theme.breakpoints.up("sm")]: {
                                        fontSize: "1.75rem",
                                    },
                                })}
                            >
                                1,000 ลิตร = 1 คะแนน
                            </Typography>
                            <Box component="img" src={giftBox} alt="Gift box" sx={{ width: "50px" }} />
                        </Box>
                        <Typography
                            component="p"
                            sx={(theme) => ({
                                fontSize: "1rem",
                                fontWeight: "500",
                                color: "#004B61",
                                textAlign: "center",
                                [theme.breakpoints.up("sm")]: {
                                    fontSize: "1.25rem",
                                },
                            })}
                        >
                            ตั้งแต่ 1 มกราคม 2567 - 30 พฤศจิกายน 2567
                            <br />
                            หมดเขตแลกของรางวัล 10 ธันวาคม 2567
                        </Typography>
                    </Box>
                    <Box
                        component="img"
                        src={homeBg}
                        alt="Homepage Bg"
                        sx={{ width: "100%", position: "absolute", bottom: "0", left: "0", zIndex: "0" }}
                    />
                </Container>
            </Box>
        </>
    );
};

export default HomePage;
