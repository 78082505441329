import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { baseURL } from "../../api/url";
import { useLineContext } from "../../hooks/useLineContext";
import Footer from "../../layouts/Footer";
import axios from "axios";
import DialogError from "../../components/ui/DialogError";
import mainBg from "../../assets/images/bg/main-bg.png";
import logo from "../../assets/images/logo/main-logo.png";
import feedback01 from "../../assets/images/pages/evaluation/Feedback-01.png";
import feedback02 from "../../assets/images/pages/evaluation/Feedback-02.png";
import feedback03 from "../../assets/images/pages/evaluation/Feedback-03.png";
import feedback04 from "../../assets/images/pages/evaluation/Feedback-04.png";
import feedback05 from "../../assets/images/pages/evaluation/Feedback-05.png";
import giftbox from "../../assets/icon/giftbox.svg";
import liff from "@line/liff";

const EvaluationPage = () => {
    const navigate = useNavigate();
    const { evaluateId } = useParams();
    const { liffToken } = useLineContext();
    const [feedbackValue, setFeedbackValue] = useState("");
    const [feedbackComment, setFeedbackComment] = useState("");
    const [feedbackError, setFeedbackError] = useState(false);
    const [displayError, setDisplayError] = useState(false);

    const onFeedbackClick = (value) => {
        setDisplayError(false);
        setFeedbackValue(value);
    };

    const sendFeedback = () => {
        if (feedbackValue) {
            setDisplayError(false);
            submitEvaluate();
        } else {
            setDisplayError(true);
        }
    };

    const cancelFeedback = () => {
        liff.closeWindow();
    };

    const checkEvaluate = useCallback(async () => {
        try {
            const response = await axios.post(
                `${baseURL}/check_evaluation`,
                { evaluation_id: evaluateId },
                { headers: { Authorization: `Bearer ${liffToken}` } }
            );

            if (response) {
                if (response.data.Code !== "20000") {
                    navigate("/404");
                } else {
                    if (response.data.Data.submitted) {
                        navigate("/evaluate_satisfaction/thankyou");
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
        // eslint-disable-next-line
    }, [evaluateId, liffToken]);

    const submitEvaluate = useCallback(async () => {
        try {
            const response = await axios.post(
                `${baseURL}/submit_evaluation`,
                { evaluation_id: evaluateId, rating: feedbackValue, comment: feedbackComment },
                { headers: { Authorization: `Bearer ${liffToken}` } }
            );

            if (response) {
                if (response.data.Code === "20000") {
                    navigate("/evaluate_satisfaction/thankyou");
                }

                setFeedbackError(true);
            }
        } catch (e) {
            setFeedbackError(true);
            console.error(e);
        }
        // eslint-disable-next-line
    }, [feedbackValue, feedbackComment, liffToken]);

    useEffect(() => {
        if (evaluateId && liffToken) {
            checkEvaluate();
        }
    }, [evaluateId, liffToken, checkEvaluate]);

    return (
        <>
            <Helmet>
                <title>Evaluation | Caltex StarCard</title>
            </Helmet>
            <Box
                component="main"
                maxWidth="md"
                sx={{
                    width: "100%",
                    paddingBottom: "0",
                    margin: "0 auto",
                    minHeight: "100vh",
                    background: `url(${mainBg}) no-repeat center center / cover`,
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Container component="section" maxWidth="md" sx={{ padding: "1rem 1.25rem 3.5rem" }}>
                    {/* Header */}
                    <Box
                        component="img"
                        src={logo}
                        alt="Logo"
                        sx={{ width: "100%", maxWidth: "400px", display: "block", margin: "0 auto" }}
                    />
                    <Typography
                        component="h1"
                        sx={{
                            fontSize: "1.5rem",
                            fontWeight: "700",
                            textAlign: "center",
                            color: "white",
                            letterSpacing: "0.05rem",
                            marginBottom: "1rem",
                        }}
                    >
                        โปรดประเมินความพึงพอใจ
                        <br />
                        ในการให้บริการครั้งนี้
                    </Typography>
                    <Typography
                        component="p"
                        sx={{
                            fontSize: ".9rem",
                            fontWeight: "500",
                            textAlign: "center",
                            color: "white",
                            letterSpacing: "0.05rem",
                        }}
                    >
                        เลือกระดับความพึงพอใจของท่าน{" "}
                        <Box component="span" sx={{ fontWeight: "400" }}>
                            (จากน้อยไปมาก)
                        </Box>
                    </Typography>

                    {/* ICON */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            padding: "1rem 0 2rem",
                            maxWidth: "400px",
                            margin: "0 auto",
                        }}
                    >
                        <FeedbackIcon
                            icon={feedback01}
                            text="น้อยที่สุด"
                            rating={1}
                            value={feedbackValue}
                            onClick={() => onFeedbackClick(1)}
                        />
                        <FeedbackIcon
                            icon={feedback02}
                            text="น้อย"
                            rating={2}
                            value={feedbackValue}
                            onClick={() => onFeedbackClick(2)}
                        />
                        <FeedbackIcon
                            icon={feedback03}
                            text="ปานกลาง"
                            rating={3}
                            value={feedbackValue}
                            onClick={() => onFeedbackClick(3)}
                        />
                        <FeedbackIcon
                            icon={feedback04}
                            text="มาก"
                            rating={4}
                            value={feedbackValue}
                            onClick={() => onFeedbackClick(4)}
                        />
                        <FeedbackIcon
                            icon={feedback05}
                            text="มากที่สุด"
                            rating={5}
                            value={feedbackValue}
                            sx={{ height: "63px" }}
                            onClick={() => onFeedbackClick(5)}
                        />
                    </Box>

                    {/* Validate message */}
                    {displayError && (
                        <Typography
                            sx={{
                                fontSize: ".875rem",
                                fontWeight: "400",
                                color: "#FF0000",
                                textAlign: "center",
                                marginBottom: "20px",
                            }}
                        >
                            กรุณาให้คะแนนความพึงพอใจ
                        </Typography>
                    )}

                    {/* Comment */}
                    <Typography
                        sx={{
                            fontSize: ".875rem",
                            fontWeight: "400",
                            color: "white",
                            textAlign: "center",
                            marginBottom: "20px",
                        }}
                    >
                        ข้อเสนอแนะเพิ่มเติม เพื่อเป็นข้อมูลในการปรับปรุงการให้บริการในครั้งถัดไป (ถ้ามี)
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        minRows={2}
                        placeholder="ข้อเสนอแนะเพิ่มเติม..."
                        inputProps={{ maxLength: "250" }}
                        value={feedbackComment}
                        onChange={(e) => setFeedbackComment(e.target.value)}
                        sx={{
                            display: "block",
                            maxWidth: "450px",
                            backgroundColor: "white",
                            border: "none",
                            borderRadius: "10px",
                            margin: "0 auto 20px",
                            color: "#004B61",
                            "& .MuiInputBase-input": {
                                fontSize: ".875rem",
                                fontWeight: "300",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                        }}
                    />

                    {/* Button */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: "10px",
                            maxWidth: "450px",
                            margin: "0 auto",
                        }}
                    >
                        <Button
                            onClick={cancelFeedback}
                            sx={{
                                width: "100px",
                                height: "35px",
                                backgroundColor: "white",
                                color: "#004B61",
                                borderRadius: "10px",
                                "&:hover": {
                                    backgroundColor: "white",
                                    color: "#004B61",
                                },
                            }}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            onClick={sendFeedback}
                            sx={{
                                width: "100px",
                                height: "35px",
                                backgroundColor: feedbackValue ? "#FF0000" : '#aaa',
                                color: feedbackValue ? "white" : "#004B61",
                                borderRadius: "10px",
                                "&:hover": {
                                    backgroundColor: feedbackValue ?  "#FF0000" : "#aaa",
                                    color: feedbackValue ? "white" : "#004B61",
                                },
                            }}
                        >
                            ส่ง
                        </Button>
                    </Box>
                </Container>
                <Footer
                    renderText={true}
                    icon={giftbox}
                    iconStyle={{ width: "50px", position: "absolute", top: "10px", left: "320px" }}
                />
                <DialogError
                    isOpen={feedbackError}
                    onClose={() => setFeedbackError(false)}
                    text={`เกิดข้อผิดพลาด \nโปรดลองใหม่อีกครั้ง`}
                    btnText="ปิด"
                />
            </Box>
        </>
    );
};

const FeedbackIcon = (props) => {
    const { icon, text, onClick, sx, value, rating } = props;

    return (
        <Box
            onClick={onClick}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
            }}
        >
            <Box
                component="img"
                src={icon}
                alt={text}
                sx={{ opacity: value === rating ? "1" : ".5", height: "45px", ...sx }}
            />
            <Typography sx={{ fontSize: ".875rem", fontWeight: "300", color: "white", textAlign: "center" }}>
                ({text})
            </Typography>
        </Box>
    );
};

export default EvaluationPage;
