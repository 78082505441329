import { Button } from "@mui/material";

const WhiteButton = (props) => {
    return (
        <Button
            {...props}
            variant={props.variant || "contained"}
            sx={{
                fontSize: "1rem",
                borderRadius: "15px",
                color: "#004B61",
                backgroundColor: "white",
                border: "2px solid white",
                ...props.sx,
                "&:hover": {
                    color: "#004B61",
                    backgroundColor: "white",
                    border: "2px solid white",
                    opacity: ".85"
                },
            }}
        >
            {props.children}
        </Button>
    );
};

export default WhiteButton;
