import { Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import WhiteButton from "../../../components/ui/WhiteButton";

const RegisterPhone = (props) => {
    const formik = useFormik({
        initialValues: {
            phoneNumber: props.phoneNumber,
        },
        validationSchema: Yup.object({
            phoneNumber: Yup.string()
                .min(10, "โปรดระบุเบอร์โทรศัพท์อย่างน้อย 10 หลัก")
                .required("โปรดระบุเบอรโทรศัพท์ให้ถูกต้อง"),
        }),
        onSubmit: (values) => {
            props.setPhoneNumber(values.phoneNumber);
            props.sentRequestOTP(formik.values.phoneNumber);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid
                container
                direction="column"
                sx={{ minHeight: "calc(100vh - 272px)", padding: "0 1rem", gap: "2rem" }}
            >
                <Grid item>
                    <Box
                        sx={{
                            padding: "1.5rem 1rem",
                            borderRadius: "20px",
                            backgroundColor: "rgba(198, 236, 250, 0.4)",
                        }}
                    >
                        <Typography
                            component="h1"
                            sx={{
                                fontSize: "2.75rem",
                                fontWeight: "bold",
                                color: "white",
                                textAlign: "center",
                                marginBottom: "2.5rem",
                            }}
                        >
                            สมัครสมาชิก
                        </Typography>
                        <TextField
                            id="phoneNumber"
                            name="phoneNumber"
                            placeholder="เบอร์มือถือ"
                            variant="outlined"
                            inputProps={{ maxLength: "10", inputMode: "numeric", pattern: "[0-9]*" }}
                            value={formik.values.phoneNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                            sx={{
                                fontSize: "0.875rem",
                                "& .MuiInputBase-root": {
                                    borderRadius: "30px",
                                    backgroundColor: "white",
                                    outline: formik.errors.phoneNumber ? "2px solid red" : "none",
                                },
                                "& .MuiInputBase-input": {
                                    padding: "0 1.25rem",
                                    height: "50px",
                                    color: "#004B61",
                                },
                                "& .MuiInputBase-input::placeholder": {
                                    fontSize: "0.875rem",
                                },
                            }}
                        />
                        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                            <Typography sx={{ padding: "0.5rem", color: "white" }}>
                                {formik.errors.phoneNumber}
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <WhiteButton fullWidth type="submit" sx={{ padding: "0.5rem" }}>
                        ขอรหัส OTP
                    </WhiteButton>
                </Grid>
            </Grid>
        </form>
    );
};

export default RegisterPhone;
