import { Helmet } from "react-helmet-async";
import { Box, CircularProgress } from "@mui/material";
import mainBg from "../../assets/images/bg/main-bg.png";
import caltex from "../../assets/images/logo/main-logo.png";

const RedirectPage = () => {
    return (
        <>
            <Helmet>
                <title>Caltex StarCard</title>
            </Helmet>

            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100vh", background: `url(${mainBg}) no-repeat center center / cover` }}
            >
                <img src={caltex} alt="Caltex" style={{ height: "70px" }} />
                <Box py={4}>
                    <CircularProgress sx={{ color: "white" }} />
                </Box>
            </Box>
        </>
    );
};

export default RedirectPage;
