import { Box, Container, Grid, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import giftbox from "../assets/icon/giftbox.svg";
import giftboxActive from "../assets/icon/giftbox-gradient.svg";
import speaker from "../assets/icon/speaker.svg";
import speakerActive from "../assets/icon/speaker-gradient.svg";
import clock from "../assets/icon/clock.svg";
import clockActive from "../assets/icon/clock-gradient.svg";
import person from "../assets/icon/person.svg";
import personActive from "../assets/icon/person-gradient.svg";
import fixbarBg from "../assets/images/bg/fix-bar-bg.png";

const Fixbar = () => {
    const { pathname } = useLocation();

    const menus = [
        {
            id: 1,
            name: "Reward",
            href: "/reward",
            icon: giftbox,
            iconActive: giftboxActive,
        },
        {
            id: 2,
            name: "Privilege",
            href: "/privilege",
            icon: speaker,
            iconActive: speakerActive,
        },
        {
            id: 3,
            name: "History",
            href: "/history",
            icon: clock,
            iconActive: clockActive,
        },
        {
            id: 4,
            name: "Member",
            href: "/member",
            icon: person,
            iconActive: personActive,
        },
    ];

    return (
        <Box
            component="footer"
            maxWidth={`${pathname.includes("/admin") ? false : "md"}`}
            sx={{
                width: "100%",
                padding: ".75rem .5rem",
                background: `url(${fixbarBg}) no-repeat center center / cover, #FF0000`,
                position: "fixed",
                bottom: "0",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: "10"
            }}
        >
            <Container maxWidth="md">
                <Grid container spacing={3}>
                    {menus.map((menu) => {
                        return (
                            <Grid key={menu.id} item xs={3}>
                                <Link to={menu.href} style={{ textDecoration: "none" }}>
                                    <Typography
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: "400",
                                            color: "white",
                                            textAlign: "center",
                                        }}
                                    >
                                        {
                                            <img
                                                src={
                                                    pathname === menu.href || pathname.includes(menu.href)
                                                        ? menu.iconActive
                                                        : menu.icon
                                                }
                                                alt={menu.name}
                                                style={{ height: "50px", display: "block", margin: "0 auto -8px" }}
                                            />
                                        }
                                        {menu.name}
                                    </Typography>
                                </Link>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
        </Box>
    );
};

export default Fixbar;
