import { useLineContext } from "../../../hooks/useLineContext";
import { useAppContext } from "../../../hooks/useAppContext";
import { Box, Typography } from "@mui/material";
import caltexPointImg from "../../../assets/images/pages/member/caltex-point.png";
import caltexLineProfile from "../../../assets/images/pages/member/caltex-lineprofile.png";
import _ from "lodash";
import { Link } from "react-router-dom";

const MemberCard = () => {
    const { lineProfile } = useLineContext();
    const { userInfo, companyInfo } = useAppContext();
    
    const renderUserData = [
        {
            label: "รหัสบริษัท",
            value: userInfo.code,
        },
        {
            label: "ชื่อบริษัท",
            value: userInfo.company_name,
        },
        {
            label: "ชื่อผู้มีสิทธิ์แลกรางวัล",
            value: userInfo.people_name,
        },
        {
            label: "เกณฑ์ขั้นต่ำต่อเดือน\nของบริษัทท่าน",
            value: companyInfo.state === "ACCOUNT READY" ? userInfo.spending_criteria : "XXXX",
        },
    ];

    return (
        <Box sx={{ width: "100%", maxWidth: "450px", margin: "0 auto", borderRadius: "30px" }}>
            <Box
                display="flex"
                gap={2}
                alignItems="center"
                sx={{
                    width: "100%",
                    margin: "0 auto",
                    padding: "1rem 1rem",
                    background: "linear-gradient(0deg, #AF0000, #AF0000, #BD0000, #E40000, #FF0000, #FF0000)",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                }}
            >
                <Box>
                    {userInfo.role === "admin" && (
                        <Link to="/admin">
                            <Box
                                sx={{
                                    width: "90px",
                                    height: "90px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "50%",
                                    backgroundColor: `${
                                        _.find(companyInfo.user, { userId: lineProfile?.userId })?.owner
                                            ? "#000"
                                            : "white"
                                    } `,
                                }}
                            >
                                <img
                                    src={lineProfile?.pictureUrl || caltexLineProfile}
                                    alt="Line Profile"
                                    style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                                />
                            </Box>
                        </Link>
                    )}

                    {userInfo.role !== "admin" && (
                        <Box
                            sx={{
                                width: "90px",
                                height: "90px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "50%",
                                backgroundColor: `${
                                    _.find(companyInfo.user, { userId: lineProfile?.userId })?.owner ? "#000" : "white"
                                } `,
                            }}
                        >
                            <img
                                src={lineProfile?.pictureUrl || caltexLineProfile}
                                alt="Line Profile"
                                style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                            />
                        </Box>
                    )}
                </Box>
                <Box>
                    <Typography component="h1" sx={{ fontSize: "1.5rem", color: "white", lineHeight: "1.25rem" }}>
                        {lineProfile?.displayName || "-"}
                    </Typography>
                    <Typography component="h2" sx={{ fontSize: "1.25rem", color: "white", marginBottom: ".5rem" }}>
                        คะแนนสะสม
                    </Typography>
                    <Box display="flex" alignItems="flex-start" gap={1.25}>
                        <img src={caltexPointImg} alt="Caltex Point" style={{ width: "25px" }} />
                        <Typography component="p" sx={{ fontSize: "2rem", lineHeight: "1.5rem", color: "white" }}>
                            {userInfo.accumulated_points || "0"} <span style={{ fontSize: "1rem" }}>คะแนน</span>
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    padding: "1.25rem 2rem 2rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.25rem",
                    backgroundColor: "#EAEDF3",
                    borderBottomLeftRadius: "30px",
                    borderBottomRightRadius: "30px",
                }}
            >
                {renderUserData.map((data, index) => {
                    return (
                        <Box
                            key={Math.random() + index}
                            sx={{
                                width: "100%",
                                minHeight: "50px",
                                paddingBottom: "15px",
                                display: "flex",
                                alignItems: "flex-start",
                                borderBottom: "1px solid #C4CAE0",
                                gap: "20px",
                            }}
                        >
                            <Box width="54%">
                                <Typography
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: "500",
                                        color: "#004B61",
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    {data.label}
                                </Typography>
                            </Box>
                            <Box width="44%">
                                <Typography sx={{ fontSize: "1rem", color: "#004B61", whiteSpace: "pre-wrap" }}>
                                    {data.value}
                                </Typography>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

export default MemberCard;
