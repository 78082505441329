import { createContext, useEffect, useState } from "react";
import { useLineContext } from "../hooks/useLineContext";
import { baseURL } from "../api/url";
import { useLocation, useNavigate } from "react-router-dom";
import DialogInfo from "../components/ui/DialogInfo";
import provincesJSON from "../assets/provinces-th/provinces.json";
import amphuresJSON from "../assets/provinces-th/amphures.json";
import tambonsJSON from "../assets/provinces-th/tambons.json";
import axios from "axios";

export const AppContext = createContext(null);

const AppContextProvider = ({ children }) => {
    const { liffToken, lineProfile } = useLineContext();
    const [renderHeader, setRenderHeader] = useState(true);
    const [renderFooter, setRenderFooter] = useState(true);
    const [headerWhiteTheme, setHeaderWhiteTheme] = useState(true);
    const [dialogAdmin, setDialogAdmin] = useState(false);
    const [userInfo, setUserInfo] = useState([]);
    const [companyInfo, setCompanyInfo] = useState([]);
    const [redeemLists, setRedeemLists] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [amphures, setAmphures] = useState([]);
    const [tambons, setTambons] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (liffToken) {
            axios
                .get(`${baseURL}/userinfo`, {
                    headers: { Authorization: `Bearer ${liffToken}` },
                })
                .then((res) => {
                    if (res.data.Code === "20000") {
                        const pathname = window.location.pathname;
                        const search = location.search.substring(1);
                        const params = new URLSearchParams(search);
                        const role = params.get("role");

                        if (role === "admin" || pathname.includes("/admin")) {
                            if (res.data.Data.role === "admin") {
                                setDialogAdmin(false);

                                if (pathname.includes("/admin")) {
                                    navigate(pathname);
                                } else {
                                    navigate("/admin");
                                }
                            } else {
                                setDialogAdmin(true);
                                navigate("/404");
                            }
                        } else {
                            if (pathname !== "/") {
                                if (location.hash) {
                                    navigate(`${pathname}${location.hash}`);
                                } else {
                                    navigate(pathname);
                                }
                            } else {
                                navigate("/home");
                            }
                        }

                        setUserInfo({
                            ...res.data.Data,
                            accumulated_points: res.data.Data.accumulated_points.toFixed(2),
                            spending_amount: res.data.Data.spending_amount.toFixed(2),
                            spending_criteria: res.data.Data.spending_criteria.toFixed(2),
                        });
                    } else {
                        navigate("/register");
                    }
                })
                .catch((e) => {
                    alert("ไม่สามารถล็อกอินได้");
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [liffToken]);

    useEffect(() => {
        if (liffToken && userInfo.code) {
            if (!location.pathname.includes("/admin")) {
                axios
                    .get(`${baseURL}/companyinfo`, {
                        params: { code_company: userInfo.code },
                        headers: { Authorization: `Bearer ${liffToken}` },
                    })
                    .then((res) => {
                        if (res.data.Code === "20000") {
                            setCompanyInfo({
                                ...res.data.Data,
                                accumulated_points: res.data.Data.accumulated_points.toFixed(2),
                                spending_amount: res.data.Data.spending_amount.toFixed(2),
                                spending_criteria: res.data.Data.spending_criteria.toFixed(2),
                            });
                        }
                    })
                    .catch((e) => {
                        throw new Error("Can't get company info", e);
                    });
            }
        }
        // eslint-disable-next-line
    }, [liffToken, userInfo]);

    useEffect(() => {
        setProvinces(provincesJSON);
        setAmphures(amphuresJSON);
        setTambons(tambonsJSON);
    }, []);

    return (
        <>
            <AppContext.Provider
                value={{
                    userInfo,
                    setUserInfo,
                    companyInfo,
                    setCompanyInfo,
                    renderHeader,
                    setRenderHeader,
                    renderFooter,
                    setRenderFooter,
                    headerWhiteTheme,
                    setHeaderWhiteTheme,
                    redeemLists,
                    setRedeemLists,
                    provinces,
                    amphures,
                    tambons,
                }}
            >
                {children}
            </AppContext.Provider>

            <DialogInfo
                isOpen={dialogAdmin}
                onClose={() => setDialogAdmin(false)}
                text={`ท่านไม่ใช่แอดมิน\n UserId: ${lineProfile.userId}`}
                btnText="ปิด"
            />
        </>
    );
};

export default AppContextProvider;
