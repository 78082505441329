import InputField from "./InputField";

const InputQty = (props) => {
    return (
        <InputField
            fullWidth
            placeholder="ระบุจำนวน"
            type="text"
            value={props.value}
            onChange={props.onChange}
            inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                autoComplete: "off",
                readOnly: true,
                style: { textAlign: "center" },
            }}
        />
    );
};

export default InputQty;
