import { Box, Typography } from "@mui/material";
import footerBar from "../assets/images/bg/footer-bar.png";

const Footer = (props) => {
    const { renderText, icon, iconStyle } = props;

    return (
        <Box
            component="footer"
            maxWidth="md"
            mx="auto"
            sx={{
                width: "100%",
                height: "100px",
                display: "flex",
                flexDirection: "column",
                justifyContent: renderText ? "space-between" : "flex-end",
                position: "relative"
            }}
        >
            {renderText && (
                <Box sx={{ padding: "0 1.25rem" }}>
                    <Typography component="h3" sx={{ fontSize: "1.5rem", color: "white" }}>
                        สตาร์การ์ด คลับ
                    </Typography>
                    <Typography component="p" sx={{ fontSize: "1rem", color: "white" }}>
                        เปลี่ยนทุกลิตรเป็นคะแนนเพื่อแลกของรางวัล
                    </Typography>
                </Box>
            )}
            <Box component="img" src={footerBar} alt="Footer Bar" sx={{ width: "100%" }} />
            {icon && <Box component="img" src={icon} alt="Icon" sx={{ ...iconStyle }} />}
        </Box>
    );
};

export default Footer;
