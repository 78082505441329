import Countdown from "react-countdown";

const RegisterCountDown = () => {
    const countdown = 120000;
    
    const renderCountdown = ({ minutes, seconds }) => {
        return (
            <span>
                {minutes} : {seconds}
            </span>
        );
    };

    return <Countdown date={Date.now() + countdown} renderer={renderCountdown} />;
};

export default RegisterCountDown;
