import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "../../../hooks/useAppContext";
import { useLineContext } from "../../../hooks/useLineContext";
import { baseURL } from "../../../api/url";
import OtpInput from "react18-input-otp";
import DialogInfo from "../../../components/ui/DialogInfo";
import axios from "axios";

const MyOTPInput = (props) => {
    const { liffToken } = useLineContext();
    const { setRenderFooter } = useAppContext();
    const [OTP, setOTP] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const onOTPChange = (value) => setOTP(value);
    const onCloseDialog = () => setIsOpen(false);

    const onOTPSubmit = useCallback(async () => {
        try {
            const response = await axios.post(
                `${baseURL}/validation_otp`,
                {
                    phonenumber: props.phoneNumber,
                    otp_code: OTP,
                },
                {
                    headers: { Authorization: `Bearer ${liffToken}` },
                }
            );

            if (response?.data?.Data) {
                setRenderFooter(false);
                props.setActiveStep(3);
            } else {
                setIsOpen(true);
                setOTP("");
            }
        } catch (e) {}
    }, [OTP, setRenderFooter, props, liffToken]);

    useEffect(() => {
        if (_.size(OTP) === 6) {
            onOTPSubmit();
        }
    }, [OTP, onOTPSubmit]);

    return (
        <>
            <OtpInput
                isInputNum
                shouldAutoFocus
                autoComplete="true"
                value={OTP}
                numInputs={6}
                containerStyle={{ justifyContent: "center", gap: ".5rem" }}
                inputStyle={{ width: "45px", height: "55px", fontSize: "1rem", borderRadius: "15px", border: "none" }}
                onChange={onOTPChange}
                onSubmit={onOTPSubmit}
            />
            <DialogInfo
                isOpen={isOpen}
                onClose={onCloseDialog}
                text={"รหัส OTP ไม่ถูกต้อง \nโปรดลองใหม่อีกครั้ง"}
                btnText="ปิด"
            />
        </>
    );
};

export default MyOTPInput;
