import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Button } from "@mui/material";
import mainBgWhite from "../../assets/images/bg/main-bg-white.png";
import AdminTabOne from "./components/AdminTabOne";
import AdminTabTwo from "./components/AdminTabTwo";
import AdminTabThree from "./components/AdminTabThree";

const AdminPage = () => {
    const [tabActive, setTabActive] = useState(1);

    return (
        <>
            <Helmet>
                <title>รายชื่อบริษัททั้งหมด | Caltex StarCard</title>
            </Helmet>

            <Box
                component="main"
                mx="auto"
                sx={{
                    minHeight: "100vh",
                    background: `url(${mainBgWhite}) no-repeat center center / cover`,
                    backgroundAttachment: "fixed",
                }}
            >
                <Box component="section" maxWidth="lg" mx="auto" sx={{ padding: "6rem 1.25rem" }}>
                    <Box
                        sx={{
                            width: "fit-content",
                            display: "flex",
                            alignItems: "center",
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                        }}
                    >
                        <Button
                            onClick={() => setTabActive(1)}
                            sx={{
                                color: tabActive === 1 ? "white" : "#002B38",
                                backgroundColor: tabActive === 1 ? "#002B38" : "white",
                                borderRadius: "0",
                                borderTopLeftRadius: ".5rem",
                                padding: ".75rem 2rem",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#002B38",
                                },
                            }}
                        >
                            รายชื่อบริษัททั้งหมด
                        </Button>
                        <Button
                            onClick={() => setTabActive(2)}
                            sx={{
                                color: tabActive === 2 ? "white" : "#002B38",
                                backgroundColor: tabActive === 2 ? "#002B38" : "white",
                                borderRadius: "0",
                                padding: ".75rem 2rem",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#002B38",
                                },
                            }}
                        >
                            ประวัติการแลกรางวัล
                        </Button>
                        <Button
                            onClick={() => setTabActive(3)}
                            sx={{
                                color: tabActive === 3 ? "white" : "#002B38",
                                backgroundColor: tabActive === 3 ? "#002B38" : "white",
                                borderRadius: "0",
                                borderTopRightRadius: ".5rem",
                                padding: ".75rem 2rem",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#002B38",
                                },
                            }}
                        >
                            ประเมินความพอใจ
                        </Button>
                    </Box>

                    {tabActive === 1 && <AdminTabOne />}
                    {tabActive === 2 && <AdminTabTwo />}
                    {tabActive === 3 && <AdminTabThree />}
                </Box>
            </Box>
        </>
    );
};

export default AdminPage;
