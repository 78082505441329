import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useLineContext } from "../../../hooks/useLineContext";
import { baseURL } from "../../../api/url";
import { privilegeTypeEnum } from "../../../assets/js/utils";
import PrivilegeItem from "./PrivilegeItem";
import EmptyCard from "../../../components/ui/EmptyCard";
import axios from "axios";

const PrivilegeList = () => {
    const { liffToken } = useLineContext();
    const [listPrivilege, setListPrivilege] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const renderPrivilegeList = (lists) => {
        if (lists.length > 0) {
            return lists.map((item) => {
                if (item.type === privilegeTypeEnum.GROUP) {
                    return (
                        <PrivilegeItem
                            type={item.type}
                            key={item?._id}
                            id={item?._id}
                            topic={item?.topic}
                            children={item?.data}
                        />
                    );
                }

                if (item.type === privilegeTypeEnum.CARD) {
                    return (
                        <PrivilegeItem
                            type={item.type}
                            key={item?._id}
                            id={item?._id}
                            topic={item?.topic}
                            img={item?.image_url}
                            url={item?.external_url}
                        />
                    );
                }

                return []
            });
        } else {
            if (isLoading) {
                return;
            }

            return <EmptyCard text="ไม่พบข่าวสาร" />;
        }
    };

    useEffect(() => {
        if (liffToken) {
            axios
                .get(`${baseURL}/listnews`, {
                    headers: { Authorization: `Bearer ${liffToken}` },
                })
                .then((res) => {
                    if (res.data.Code === "20000") {
                        setListPrivilege(res.data.Data);
                    } else {
                        setListPrivilege([]);
                    }

                    setIsLoading(false);
                })
                .catch((e) => {
                    throw new Error("Can't get list of news");
                });
        }
    }, [liffToken]);

    return (
        <>
            <Box display="flex" flexDirection="column" gap={1} p={2.5}>
                {isLoading && <CircularProgress thickness={5} sx={{ display: "block", margin: "20px auto" }} />}
                {renderPrivilegeList(listPrivilege)}
            </Box>
        </>
    );
};

export default PrivilegeList;
