import _ from "lodash";
import { useEffect, useState } from "react";
import { useAppContext } from "../../hooks/useAppContext";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useLineContext } from "../../hooks/useLineContext";
import { baseURL } from "../../api/url";
import axios from "axios";
import parse from "html-react-parser";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import mainBgWhite from "../../assets/images/bg/main-bg-white.png";
import speakerGradient from "../../assets/icon/speaker-gradient.svg";
import starRedIcon from "../../assets/icon/star-red.svg";

const PrivilegeDetailPage = () => {
    const { liffToken } = useLineContext();
    const { userInfo } = useAppContext();
    const { privilegeId } = useParams();
    const [listPrivilege, setListPrivilege] = useState([]);
    const [currentPrivilege, setCurrentPrivilege] = useState(null);

    useEffect(() => {
        if (liffToken) {
            axios
                .get(`${baseURL}/listnews`, {
                    headers: { Authorization: `Bearer ${liffToken}` },
                })
                .then((res) => {
                    if (res.data.Code === "20000") {
                        setListPrivilege(res.data.Data);
                    }
                })
                .catch((e) => {
                    throw new Error("Can't get list of news");
                });
        }
    }, [liffToken]);

    useEffect(() => {
        const privilege = _.find(listPrivilege, { _id: privilegeId });
        setCurrentPrivilege(privilege);
    }, [listPrivilege, privilegeId]);

    return (
        <>
            <Helmet>
                <title>{currentPrivilege?.topic || "Privilege Detail"} | Caltex StarCard</title>
            </Helmet>

            <Box
                component="main"
                maxWidth="md"
                mx="auto"
                sx={{
                    background: `url(${mainBgWhite}) no-repeat center center / cover`,
                    backgroundAttachment: "fixed",
                }}
            >
                <Box component="section" sx={{ padding: "6rem 0" }}>
                    <Box
                        sx={{
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        <Box
                            component="img"
                            src={currentPrivilege?.image_url}
                            alt="Reward Background"
                            sx={{ width: "100%" }}
                        />
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "absolute",
                                top: "0",
                                left: "0",
                                padding: ".5rem 1.5rem .5rem 2rem",
                            }}
                        >
                            <Box sx={{ fontSize: "1.5rem", fontWeight: "500", color: "#004B61" }}>Privilege</Box>
                            <Box>
                                <Box sx={{ fontSize: "1rem", fontWeight: "500", color: "#FF0000", textAlign: "right" }}>
                                    คะแนนสะสม
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "5px",
                                        fontSize: "1.75rem",
                                        lineHeight: "1.75rem",
                                        fontWeight: "500",
                                        color: "#FF0000",
                                    }}
                                >
                                    <Box component="img" src={starRedIcon} alt="Star" sx={{ height: "22px" }} />
                                    {userInfo?.accumulated_points || "0"}
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box p={2}>
                        <Grid
                            container
                            p={2}
                            mb={2}
                            sx={{
                                border: "2px solid white",
                                borderRadius: "15px",
                                background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
                            }}
                        >
                            <Grid item xs={2}>
                                <img src={speakerGradient} alt="Privilege" style={{ height: "30px" }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography sx={{ fontSize: "1rem", fontWeight: "500", color: "#004B61" }}>
                                    {currentPrivilege?.topic || "-"}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            p={2}
                            mb={2}
                            sx={{
                                border: "2px solid white",
                                borderRadius: "15px",
                                background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
                            }}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontSize: ".875rem",
                                        color: "#004B61",
                                        "& a": { color: "#004B61", fontWeight: "500" },
                                    }}
                                >
                                    {currentPrivilege ? parse(currentPrivilege?.detail) : "-"}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box>
                            <Link to="/privilege">
                                <Button
                                    variant="contained"
                                    startIcon={<ArrowBackIosIcon />}
                                    sx={{
                                        width: "120px",
                                        height: "44px",
                                        borderRadius: "15px",
                                        background: "linear-gradient(0deg, #365CA0, #264373, #152741)",
                                        fontSize: "1rem",
                                    }}
                                >
                                    กลับ
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default PrivilegeDetailPage;
