import { Box, Grid, Typography } from "@mui/material";
import RedButton from "../../../components/ui/RedButton";
import RegisterOtpButton from "./RegisterOtpButton";
import RegisterOtpInput from "./RegisterOtpInput";
import RegisterCountDown from "./RegisterCountdown";

const RegisterOTP = (props) => {
    const onEditPhoneNumber = () => {
        props.setActiveStep(1);
    };

    return (
        <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            sx={{ minHeight: "calc(100vh - 272px)", padding: "0 1rem" }}
        >
            <Grid item sx={{ width: "100%" }}>
                <Box
                    sx={{
                        padding: "1.5rem 1rem 2.5rem",
                        borderRadius: "20px",
                        backgroundColor: "rgba(198, 236, 250, 0.4)",
                    }}
                >
                    <Typography
                        component="h1"
                        sx={{
                            fontSize: "2.75rem",
                            fontWeight: "bold",
                            color: "white",
                            textAlign: "center",
                            marginBottom: "0.5rem",
                        }}
                    >
                        สมัครสมาชิก
                    </Typography>
                    <RegisterOtpInput
                        phoneNumber={props.phoneNumber}
                        setActiveStep={props.setActiveStep}
                        lineToken={props.lineToken}
                    />
                </Box>
                <Typography
                    component="p"
                    sx={{ fontSize: "1rem", color: "white", textAlign: "center", margin: "1.5rem 0" }}
                >
                    รหัส OTP ไม่ถูกต้องกรุณากรอกใหม่อีกครั้ง
                </Typography>
                <Typography
                    component="p"
                    sx={{
                        fontSize: "1rem",
                        color: "white",
                        textAlign: "center",
                        marginBottom: "2.5rem",
                    }}
                >
                    เหลือเวลาอีก <RegisterCountDown /> นาที
                </Typography>
            </Grid>
            <Grid item sx={{ width: "100%" }}>
                <Grid container>
                    <Grid item xs={6}>
                        <RedButton
                            onClick={onEditPhoneNumber}
                            sx={{
                                padding: "0.5rem 1.2rem",
                            }}
                        >
                            แก้ไขเบอร์มือถือ
                        </RedButton>
                    </Grid>
                    <Grid item xs={6}>
                        <RegisterOtpButton phoneNumber={props.phoneNumber} sentRequestOTP={props.sentRequestOTP} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RegisterOTP;
