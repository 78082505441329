import { Accordion, AccordionDetails, AccordionSummary, Box, Link, Typography } from "@mui/material";
import { privilegeTypeEnum } from "../../../assets/js/utils";
import speakerGradient from "../../../assets/icon/speaker-gradient.svg";
import arrowRightGradient from "../../../assets/icon/arrow-right-gradient.svg";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PrivilegeItem = (props) => {
    const { topic, img, url, type, children } = props;
    const [expanded, setExpanded] = useState(false);
    const ref = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const encodedId = encodeURIComponent(topic);

    const handleChange = (e, isExpanded) => {
        setExpanded(isExpanded);
        if (isExpanded) {
            navigate(`/privilege#${encodedId}`);
            ref.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        if (location.hash === `#${encodedId}`) {
            setExpanded(true);
            ref.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [location.hash, encodedId]);

    return (
        <div ref={ref}>
            <Accordion
                expanded={expanded}
                onChange={handleChange}
                sx={{
                    borderRadius: "20px !important",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                    "&::before": {
                        display: "none",
                    },
                    "&.Mui-expanded": {
                        margin: "0",
                    },
                }}
            >
                <AccordionSummary
                    id={topic}
                    aria-controls={`${topic}-content`}
                    expandIcon={<Box component="img" src={arrowRightGradient} sx={{ width: "15px" }} />}
                    sx={{
                        borderRadius: "20px !important",
                        background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
                        "&.Mui-expanded": {
                            minHeight: "unset",
                        },
                        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                            transform: "rotate(90deg)",
                        },
                        "& .MuiAccordionSummary-content.Mui-expanded": {
                            margin: "12px 0",
                        },
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <Box component="img" src={speakerGradient} sx={{ width: "45px" }} />
                        <Typography sx={{ fontSize: ".875rem", fontWeight: "500", color: "#004B61" }}>
                            {topic}
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding: "10px 0",
                        backgroundColor: "transparent",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                    }}
                >
                    {type === privilegeTypeEnum.CARD && (
                        <Link href={url} target="_blank">
                            <Box component="img" src={img} alt="Privilege" sx={{ width: "100%" }} />
                        </Link>
                    )}

                    {type === privilegeTypeEnum.GROUP &&
                        children.map((child) => (
                            <Link key={child.topic} href={child.external_url} target="_blank">
                                <Box component="img" src={child.image_url} alt="Privilege" sx={{ width: "100%" }} />
                            </Link>
                        ))}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default PrivilegeItem;
